import React, { useState, useEffect } from 'react';
import { banner, laptop, smartphone } from '../../../assets';
import useTypewriter from 'react-typewriter-hook';
import categoryModelApi from '../../../api/categoryModelApi';
import SearchForm from './search-form/SearchForm';
import './banner.css';

const Banner = () => {
    const [selectedDeviceType, setSelectedDeviceType] = useState(null);
    const [devicetypes, setDevicetypes] = useState([]);
    const [isPlusClicked, setIsPlusClicked] = useState(false);

    useEffect(() => {
        const getDeviceTypes = async () => {
            try {
                const { data } = await categoryModelApi.getAll();
                const validData = data.filter(deviceType => deviceType?.name); // Exclure les données invalides
                setDevicetypes(validData);
            } catch (error) {
                console.error("Erreur lors de la récupération des types d'appareils :", error);
            }
        };
        getDeviceTypes();
    }, []);

    function MagicWriter(word) {
        const typing = useTypewriter(word);
        return typing;
    }

    const changeMode = mode => {
        setSelectedDeviceType(mode);
        setIsPlusClicked(false); // Réinitialiser l'état de "+"
    };

    return (
        <div className="relative w-full inline-block">
            <div className="absolute inset-0 bg-black opacity-50"></div>
            <img
                src={banner}
                alt="Image"
                className={`block w-full object-cover ${selectedDeviceType || isPlusClicked ? "h-[110vh]" : "h-[75vh] md:h-[100vh]"}`}
            />
            <div className="absolute top-20 md:px-5 px-3 justify-center items-center sm:row md:flex inset-0 text-center md:text-left">
                <div className="mr-11 col-md-5 mb-7 md:mb-1">
                    <h1 className="text-5xl font-extrabold text-white tracking-tight">
                        {MagicWriter("Bienvenue sur Fixify !")}
                    </h1>
                    <p className="text-lg text-gray-200 mt-4">
                        Trouvez les meilleurs professionnels de réparation au Maroc
                    </p>
                </div>

                {selectedDeviceType || isPlusClicked ? (
                    <div className="search-form-container">
                        <SearchForm
                            page={"home"}
                            selectedDeviceType={selectedDeviceType}
                            setSelectedDeviceType={setSelectedDeviceType}
                        />
                    </div>
                ) : (
                    <div className="btns flex col-md-4 justify-center md:justify-start mt-10 md:mt-0 space-x-6 items-center">
                        <div className="text-white text-lg">
                            <p className="text-center md:text-left">S'il vous plaît, choisissez votre appareil :</p>
                            <div className="flex justify-center md:justify-start mt-6 space-x-4">
                                {devicetypes.slice(0, 2).map((deviceType, index) => (
                                    <span
                                        key={deviceType?.name || index}
                                        onClick={() => deviceType?.name && changeMode(deviceType.name)}
                                        className="text-white cursor-pointer border-2 border-white p-6 rounded-full transform hover:scale-105 transition duration-300 ease-in-out w-32 h-32 flex flex-col justify-center items-center"
                                    >
                                        <img
                                            src={deviceType?.name === "Ordinateur" ? laptop : smartphone}
                                            className="w-12 h-auto icon transition-transform duration-300 transform hover:scale-125"
                                            alt={deviceType?.name || "unknown"}
                                        />
                                        <p className="text-center mt-2">{deviceType?.name}</p>
                                    </span>
                                ))}
                                <span
                                    onClick={() => setIsPlusClicked(true)}
                                    className="text-white cursor-pointer border-2 border-white p-6 rounded-full flex justify-center items-center w-32 h-32 transform hover:scale-105 transition duration-300 ease-in-out"
                                >
                                    <p className="text-center text-sm">Autre</p>
                                </span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Banner;
