import React, { useEffect, useRef, useState } from 'react';
import AdminLayout from '../../../../layouts/AdminLayout';
import { Button, Breadcrumb, Input, Space, Table, Modal, Form, Popconfirm, Dropdown, Menu, message, Upload, notification } from 'antd';
import { DownOutlined, EllipsisOutlined, HomeOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { createCategoryModel, deleteCategoryModel, getAllCategoriesModel, updateCategoryModel } from '../../../../store/dashboard/category/CategoryModelAction';
import { useDispatch, useSelector } from 'react-redux';
import categoryModelApi from '../../../../api/categoryModelApi';
import * as XLSX from 'xlsx'; // Import the xlsx library
import axios from 'axios';

const CategoryModelList = () => {
    const { categories, loading } = useSelector((state) => state.category);
    const [showAddModal, setShowAddModal] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [categoryId, setCategoryId] = useState(0);
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [form] = Form.useForm();
    const [name, setName] = useState(null);

    // Fonction de toast pour les erreurs
    const errorToast = (message) => {
        notification.error({
            message: 'Erreur',
            description: message,
            duration: 2,
        });
    };

    // Fonction de toast pour les succès
    const successToast = (message) => {
        notification.success({
            message: 'Succès',
            description: message,
            duration: 2,
        });
    };

    const checkNameAvailability = (rule, value) => {
        if (!value) return Promise.resolve();
        if (!editMode || name.trim() !== value.trim()) {
            return categoryModelApi.checkName({ name: value, description: "" })
                .then(() => Promise.resolve())
                .catch(() => Promise.reject('Name is already taken'));
        }
        return Promise.resolve();
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const handleShowEditModal = (category) => {
        setShowAddModal(true);
        setCategoryId(category.id);
        setEditMode(true);
        form.setFieldsValue(category);
        setName(category.name);
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90 }}>
                        Search
                    </Button>
                    <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button type="link" size="small" onClick={() => close()}>
                        Close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
        onFilter: (value, record) => record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }} searchWords={[searchText]} autoEscape textToHighlight={text ? text.toString() : ''} />
            ) : (
                text
            ),
    });

    const menu = (category) => (
        <Menu>
            <Menu.Item key="1" onClick={() => handleShowEditModal(category)}>Modifier</Menu.Item>
            <Menu.Item key="2">
                <Popconfirm
                    title="Êtes-vous sûr de vouloir supprimer cette catégorie ?"
                    onConfirm={() => handleDelete(category.id)}
                    okText="Oui"
                    cancelText="Non"
                >
                    Supprimer
                </Popconfirm>
            </Menu.Item>
        </Menu>
    );

    const columns = [
        { title: 'Nom', dataIndex: 'name', key: 'name', width: 200, ...getColumnSearchProps('name') },
        { title: 'Description', dataIndex: 'description' },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            fixed: 'right',
            width: 100,
            render: (_, category) => (
                <Dropdown overlay={() => menu(category)} trigger={['click']}>
                    <EllipsisOutlined className='cursor-pointer text-blue-500 text-lg' />
                </Dropdown>
            ),
        },
    ];

    const handleDelete = (id) => dispatch(deleteCategoryModel(id));

    const handleFileUpload = (file) => {
        const formData = new FormData();
        formData.append("file", file); // Vérifie que "file" est bien envoyé
        
        console.log("FormData:", formData);
        
        fetch("http://fixi-fy.com/api/categorymodel/upload", {
            method: "POST",
            body: formData,
        }).then(response => {
            if (response.ok) {
                console.log("Success:", response);
            } else {
                response.text().then(err => console.error("Error:", err));
            }
        }).catch(err => console.error("Fetch error:", err));
        
    };

    useEffect(() => {
        dispatch(getAllCategoriesModel());
    }, [dispatch]);

    return (
        <AdminLayout>
            {contextHolder}
            <div className="flex items-center">
                <Breadcrumb items={[{ title: <HomeOutlined /> }, { title: 'Liste des catégories' }]} />
                <Button onClick={() => setShowAddModal(true)} className="ml-auto" type="primary">Ajouter Catégorie</Button>
                <Upload accept=".xls,.xlsx" showUploadList={false} customRequest={({ file }) => handleFileUpload(file)}>
                    <Button icon={<UploadOutlined />} type="primary" className="ml-2">Upload Excel</Button>
                </Upload>
            </div>
            <Table columns={columns} dataSource={categories} loading={loading} pagination={{ pageSize: 8 }} className="mt-3" />
            <Modal
                visible={showAddModal}
                title={editMode ? "Modifier Catégorie" : "Ajouter Catégorie"}
                okText={editMode ? "Modifier" : "Ajouter"}
                confirmLoading={loading}
                onCancel={() => setShowAddModal(false)}
                onOk={() => form.validateFields().then(values => {
                    if (editMode) dispatch(updateCategoryModel({ categoryModelData: values, id: categoryId }));
                    else dispatch(createCategoryModel(values));
                    setShowAddModal(false);
                    form.resetFields();
                })}
            >
                <Form form={form} layout="vertical">
                    <Form.Item name="name" label="Nom" rules={[{ required: true, message: 'Veuillez entrer le nom' }, { validator: checkNameAvailability }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="description" label="Description" rules={[{  message: 'Veuillez entrer la description' }]}>
                        <Input.TextArea />
                    </Form.Item>
                </Form>
            </Modal>
        </AdminLayout>
    );
};

export default CategoryModelList;
