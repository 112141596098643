import React, { useEffect, useRef, useState } from 'react';
import Adminlayout from '../../../../layouts/AdminLayout';
import { Badge, Breadcrumb, Dropdown, Input, Menu, Space, Table, Modal, Form, Select, Button, Popconfirm } from 'antd';
import { DownOutlined, EllipsisOutlined, HomeOutlined, SearchOutlined } from '@ant-design/icons';
import { create, getAll, update, remove } from '../../../../store/dashboard/problemType/problemTypeAction';
import { useDispatch, useSelector } from 'react-redux';
import Highlighter from 'react-highlight-words';
import ProblemTypeApi from '../../../../api/ProblemTypeApi';

const ProblemList = () => {
    const { problems, loading } = useSelector((state) => state.problemType);
    const [showAddModal, setshowAddModal] = useState(false);
    const [editmode, seteditmode] = useState(false);
    const [problemId, setproblemId] = useState(0);
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [form] = Form.useForm();
    const [models, setModels] = useState([]);  // Renommé de 'categories' à 'models'
    const [name, setName] = useState(null);
    const [modelId2, setModelId] = useState(null);  // Renommé de 'categoryId2' à 'modelId2'

    const checkNameAvailability = async (rule, value) => {
        console.log("rah kaytchiki");
        if (!value) {
            return Promise.resolve();
        }
        const modelId = form.getFieldValue('modelId');  // Renommé de 'categoryId' à 'modelId'
        if (!modelId) {
            return Promise.reject('Sélectionnez un modèle d\'abord');
        }
        try {
            if (!editmode) {
                await ProblemTypeApi.checkName(modelId, { name: value, description: "" });
            } else {
                console.log(name.trim() !== value.trim() && modelId2 !== modelId);
                if (name.trim() !== value.trim() || modelId2 !== modelId) {
                    await ProblemTypeApi.checkName(modelId, { name: value, description: "" });
                }
            }
            return Promise.resolve();
        } catch (error) {
            return Promise.reject('Nom déjà pris pour ce modèle');
        }
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const handleShowEditModal = (problem) => {
        setName(problem.name);
        setModelId(problem.modelId);  // Renommé de 'categoryId' à 'modelId'
        setshowAddModal(true);
        setproblemId(problem.id);
        seteditmode(true);
        console.log(problem);
        form.setFieldsValue(problem);
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => { close(); }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
        ),
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleDelete = (id) => {
        dispatch(remove(id));
    };

    const handleModelChange = (value) => {
        form.setFieldsValue({ modelId: value });
        form.validateFields(['name']);
    };

    const menu = (problem) => (
        <Menu>
            <Menu.Item key="1">
                <span onClick={() => handleShowEditModal(problem)} >Modifier</span>
            </Menu.Item>
            <Menu.Item key="2">
                <Popconfirm
                    title="Delete the task"
                    description="Are you sure to delete this task?"
                    onConfirm={() => handleDelete(problem.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <span >Suprimer</span>
                </Popconfirm>
            </Menu.Item>
        </Menu>
    );

    const columns = [
        {
            title: 'Nom',
            dataIndex: 'name',
            key: 'name',
            width: 200,
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Description',
            dataIndex: 'description',
        },
        {
            title: 'Modèle',  // Remplace 'Catégorie' par 'Modèle'
            dataIndex: 'model',
            width: 200,
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: 120,
            align: 'center',
            fixed: 'right',
            render: (text, problem) => (
                <Dropdown overlay={() => menu(problem)} trigger={['click']}>
                    <span className='cursor-pointer'>
                        <EllipsisOutlined className='text-blue-500 text-lg' />
                        <DownOutlined className='text-blue-500 ml-1' />
                    </span>
                </Dropdown>
            ),
        },
    ];

    const handleShowAddModal = () => {
        form.resetFields();
        setshowAddModal(true);
        seteditmode(false);
    };

    useEffect(() => {
        dispatch(getAll());
        const fetchModels = async () => {  // Remplace 'fetchCategories' par 'fetchModels'
            const fetchedModels = await ProblemTypeApi.getAllModels();  // Remplace 'getAllCategories' par 'getAllModels'
            const filteredData = fetchedModels.data.filter(deviceType => deviceType.name.toLowerCase() !== 'autre');
            setModels(filteredData);  // Renommé de 'setCategories' à 'setModels'
        };
        fetchModels();
    }, [dispatch]);

    const tableData = problems.map((problem, key) => {
        const model = models.find(m => m.id === problem.modelId);
        console.log('Model ID:', problem.modelId);  // Debugging: Affiche l'ID du modèle
        console.log('Found model:', model);  // Debugging: Affiche le modèle trouvé
        return {
            key: key,
            id: problem.id,
            name: problem.name,
            description: problem.description,
            model: model ? <Badge status="success" text={model.name} /> : 'Modèle non trouvé',
            modelId: problem.modelId,
        };
    });
    

    const handleCancel = () => {
        setshowAddModal(false);
        form.resetFields();
    };

    return (
        <Adminlayout>
            <div className="flex items-center">
                <Breadcrumb
                    items={[
                        {
                            href: '',
                            title: <HomeOutlined />,
                        },
                        {
                            href: '',
                            title: <span>Liste des problèmes</span>,
                        },
                    ]}
                />
                <button onClick={handleShowAddModal} className="no-underline btn btn-sm btn-primary text-white ml-auto">
                    Ajouter problème
                </button>
            </div>
            <div className='mt-3'>
                <Table
                    columns={columns}
                    dataSource={tableData}
                    loading={loading}
                    pagination={{ pageSize: 8 }}
                />
            </div>

            <Modal
                open={showAddModal}
                title={editmode ? "Modifier problème" : "Ajouter problème"}
                okText={editmode ? "Modifier" : "Ajouter"}
                confirmLoading={loading}
                cancelText="Cancel"
                onCancel={handleCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then(async values => {
                            console.log("values", values);
                            if (editmode) {
                                await checkNameAvailability();
                                dispatch(update({ problem: values, id: problemId })).unwrap()
                                    .then(() => {
                                        setshowAddModal(false);
                                    });
                            } else {
                                dispatch(create(values)).unwrap()
                                    .then(() => {
                                        setshowAddModal(false);
                                    });
                            }
                        })
                        .catch(info => {
                            console.log('Validate Failed:', info);
                        });
                }}
            >
                <Form form={form} layout="vertical" name="problem-form">
                    <Form.Item
                        name="name"
                        label="Nom"
                        rules={[{ required: true, message: 'Nom est requis' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="description"
                        label="Description"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="modelId"  // Remplace 'categoryId' par 'modelId'
                        label="Modèle"
                        rules={[{ required: true, message: 'Veuillez sélectionner un modèle' }]}
                    >
                        <Select placeholder="Sélectionner un modèle" onChange={handleModelChange}>
                            {models.map(model => (
                                <Select.Option key={model.id} value={model.id}>
                                    {model.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </Adminlayout>
    );
};

export default ProblemList;
